import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css"
import CompanyDetails from "./CompanyDetails";

const CalendarComponent = (props) => {
    var events = props.filteredCompanies;
    for (let i = 0; i < props.filteredCompanies.length; i++) {
        const date = new Date(props.filteredCompanies[i].last_date);
        events[i].title = props.filteredCompanies[i].company_id.name;
        events[i].start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        events[i].end = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    const localizer = momentLocalizer(moment);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const handleEventClick = (event) => {
        setSelectedCompany(event);
    };
    const handleBack = () => {
        setSelectedCompany(null);
    };

    return (
        <div className="container">
            {selectedCompany ? (
                <div className="w-full">
                    <CompanyDetails
                        appliedCompanies={props.appliedCompanies}
                        comingCompany={selectedCompany}
                        onBack={handleBack}
                    />
                </div>
            ) : <>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectEvent={handleEventClick}
                /></>}
        </div>
    );
};

export default CalendarComponent;
