import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import First from "./Images/1.png";
import Second from "./Images/2.png";
import Third from "./Images/3.png";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Track the current slide index
  const swiperRef = useRef(null);

  // Array of slide titles and image URLs
  const slides = [
    { title: "See All Coming Companies", imageUrl: First },
    { title: "Track Applied Companies", imageUrl: Second },
    { title: "Manage All HR Contact", imageUrl: Third }
  ];

  return (
    <div className="w-full relative">
      {/* Display heading based on active slide */}
      <h2 className="text-3xl font-semibold text-indigo-600 text-center mb-4">
        {slides[activeIndex].title}
      </h2>

      <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}  // Autoplay every 3 seconds
        navigation={{
          prevEl: '#slider-button-left',
          nextEl: '#slider-button-right',
        }}
        pagination={{ clickable: true }}
        className="default-carousel w-[80%]"
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}  // Update index on slide change
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="bg-indigo-50 rounded-2xl h-[98%] flex justify-center items-center">
              <img
                src={slide.imageUrl}
                alt={slide.title}
                className="rounded-2xl object-cover h-full w-full"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-pagination"></div>
    </div>
  );
};

export default Carousel;
