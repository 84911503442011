import React from "react";
import { sendSharedHrId } from "../../api/sendSharedHrId";
import { toast } from "react-hot-toast";

const SharedHrTableRow = ({ hr, onRemove }) => {
  const { company_name, contact_number, email, gender, name, linkedin_id } = hr;

  const handleAddClick = async () => {
    try {
      const existingHrContacts =
        JSON.parse(localStorage.getItem("hrData")) || [];

      const updatedHrContacts = [...existingHrContacts, hr];

      localStorage.setItem("hrData", JSON.stringify(updatedHrContacts));
      const response = await sendSharedHrId({ hr_id: hr.id });
      toast.success("Added to Hr List:");

      onRemove(hr);
    } catch (err) {
      toast.error("Error occurd while adding to HR list");
    }
  };
  return (
    <tr className="shared-hr-table-row">
      <td>{name}</td>
      <td>{gender}</td>
      <td>{company_name}</td>
      <td>{contact_number}</td>
      <td>{email}</td>
      <td>
        <a href={linkedin_id} target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>
      </td>
      <td>
        <button className="add-to-list-button" onClick={handleAddClick}>
          Add
        </button>
      </td>
    </tr>
  );
};

export default SharedHrTableRow;
